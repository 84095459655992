<!--
.. Added by Kishore Jalleda
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <dashboard-card
      :loading="loading"
      type="bar"
      :options="chartOptions"
      :series="series"
      title="Event Costs By Type"
    />
  </template>

  <script>
  import { forEach, sumBy } from "lodash"
  import DashboardCard from "@/dashboard/DashboardCard.vue"

  export default {
    name: "IncidentCostBarChartByTypeCard",

    props: {
      value: {
        type: Object,
        default: function () {
          return {}
        },
      },
      loading: {
        type: [String, Boolean],
        default: function () {
          return false
        },
      },
    },

    components: {
      DashboardCard,
    },

    data() {
      return {
        costsByType: {}, // Declare the variable as a data property
      }
    },

    computed: {
      chartOptions() {
        return {
          chart: {
            type: "bar",
            height: 350,
            animations: {
              enabled: false,
            },
          },
          responsive: [
            {
              options: {
                legend: {
                  position: "top",
                },
              },
            },
          ],
          xaxis: {
            categories: this.categoryData || [],
            title: {
              text: "Incident Cost Type",
            },
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function (val) {
                var formatter = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumSignificantDigits: 6,
                })

                return formatter.format(val) /* $2,500.00 */
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumSignificantDigits: 6,
              })

              return formatter.format(val) /* $2,500.00 */
            },
          },
        }
      },
      series() {
        let series = []

        // Group incident costs by type
        forEach(this.value, (value) => {
            forEach(value, (incident) => {
                forEach(incident.incident_costs, (cost) => {
                    const type = cost.incident_cost_type.name
                    if (this.costsByType[type]) {
                        this.costsByType[type].push(cost)
                    } else {
                        this.costsByType[type] = [cost]
                    }
                })
            })
        })

        // Calculate total cost for each type
        forEach(this.costsByType, (costs, type) => {
            const totalCost = sumBy(costs, "amount")
            series.push({ name: type, data: [totalCost] })
        })

        return series
      },

      categoryData() {
        return Object.keys(this.value)
      },
    },
  }
  </script>
